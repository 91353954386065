<template>
  <form role="form" class="cali__login-text-start" v-auto-animate>
    <div class="cali__login-mb-3">
      <div class="cali__login-group form-group">
        <label for="email" class="form-label secondary-label floating-label"> Correo electrónico</label> 
        <input id="email"
               type="email"
               class="form-control cali__login-input"
               name="email"
               placeholder="Correo electrónico"
               autocomplete="off"
               :class="{'cali__login-error': v$?.credentials?.email?.$errors?.length > 0}"
               v-model="credentials.email">
      </div>
    </div>

    <div class="cali__login-mb-3" v-if="options?.password?.enabled && showPasswordField">
      <div class="cali__login-group form-group">
        <label for="password" class="form-label secondary-label floating-label"> Contraseña </label>

        <input id="password"
               type="password"
               class="form-control cali__login-input"
               name="password"
               placeholder="Contraseña"
               :class="{'cali__login-error': v$?.credentials?.password?.$errors?.length > 0}"
               v-model="credentials.password">
      </div>
    </div>

    <div class="cali__login-text-center align-content-mobile">
      <button class="cali__login-button"
              :disabled="loading"
              @click.prevent="validate">
        <div v-if="loading" class="cali__login-spinner"></div>
        <span v-else>{{ showPasswordField ? 'Iniciar sesión' : 'Enviar' }}</span>
      </button>
    </div>

    <div class="cali__login-text-end align-content-mobile mt-3 font-xs"
         :class="{'cali__login-link-mt': !showPasswordField}"
         v-if="options?.password?.enabled && options?.magicLink?.enabled">
      Si prefieres, <a class="cali__login-link" @click.prevent="changeFields">
        {{ showPasswordField ? 'inicia sesión con un Magic Link' : 'inicia sesión con tu contraseña' }}
      </a>
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from '@vuelidate/validators'

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    showPasswordFromLocalStorage: {
      type: Boolean
    }
  },
  data: () => ({
    credentials: {},
    showPasswordField: false,
    loading: false
  }),
  created () {
    this.showPasswordField = this.showPasswordFromLocalStorage
  },
  computed: {
    credentialsRules() {
      let defaultRules = { credentials: { email: { required, email }}};

      if (this.showPasswordField) {
        defaultRules.credentials.password = { required }
      }

      return defaultRules
    },
    url() {
      return this.showPasswordField ? this.options?.password?.url : this.options?.magicLink?.url
    },
    headers() {
      return { Accept: "application/json", "Content-Type": "application/json", ...this.options?.headers }
    },
    type() {
      return this.showPasswordField ? 'password' : 'magic link'
    }
  },
  methods: {
    changeFields() {
      this.v$.$reset()
      this.showPasswordField = !this.showPasswordField;

      localStorage.setItem("loginMode",this.showPasswordField);

      this.$emit('changeMode', this.showPasswordField)
    },
    resetErrors() {
      this.v$.$reset();
    },
    validate() {
      this.v$.$validate().then(value => {
        if (value) {
          this.submit();
        }
      });
    },
    async submit() {
      if (this.loading) return;
      this.loading = true;

      const options = {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify({ data: { ...this.credentials, type: this.type} }),
      };

      if (this.url) {
        try {
          let response = await fetch(this.url, options)

          if (response.status >= 200 && response.status < 300) {
            this.$emit('onSubmit', { ...response, type: this.type, credentials: this.credentials })
            this.resetErrors();
          } else {
            this.$emit('onError', {
              error: await response.json(),
              type: this.type,
              credentials: this.credentials
            })
          }
        } catch (e) {
          this.$emit('onError', {
            error: e,
            type: this.type,
            credentials: this.credentials
          })
        }
      } else {
        console.error('No api configured for ' + this.type + ' request, options prop example: ', {
          magicLink: { enabled: true, url: 'api_url'},
          password: { enabled: true, url: 'api_url' },
          headers: '...your headers here'
        })
      }

      this.loading = false;
    }
  },
  validations() {
    return this.credentialsRules;
  }
}
</script>

<style>
.cali__login-spinner{
  width: 1rem !important;
  max-width: 1rem !important;
  height: 1rem !important;
  max-height: 1rem !important;
  margin: 0 !important;
}

@media (max-width: 768px) {
  .cali__login-input:-webkit-autofill {
    -webkit-text-fill-color: inherit !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.cali__login-input:focus:-webkit-autofill {
    -webkit-text-fill-color: inherit !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

  .secondary-label {color: #999999 !important;}
  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
  }
  .form-control {
    padding-top: 0.7rem;
  }
  .floating-label {
    position: absolute;
    top: -0.7rem;
    left: 0.75rem;
    background: white;
    padding-inline: 6px;
    margin-inline: 10px;
    z-index: 2;
  }

  .form-control:focus~.floating-label,
  .form-control:not(:placeholder-shown)~.floating-label {
    top: -0.75rem;
  }
  .align-content-mobile {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  input:-internal-autofill-selected {
    background-color: white !important;
  }
}
@media (max-width:428px) {
  .font-xs {
    font-size: 0.8em;
  }
}
</style>